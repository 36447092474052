import {
  required,
  email,
  numeric,
  maxLength,
  helpers,
  sameAs,
  minLength
} from "@vuelidate/validators";

const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED_ERROR: "Please enter your email address",
  EMAIL_INVALID_ERROR: "Please enter a valid email address",
  FIRST_NAME_REQUIRED_ERROR: "Please enter your first name",
  FIRST_NAME_INVALID_ERROR: "Please enter a valid first name",
  LAST_NAME_REQURIED_ERROR: "Please enter your last name",
  LAST_NAME_INVALID_ERROR: "Please enter a valid last name",
  MAX_LENGTH_ERROR: "You have reached your maximum limit of characters allowed",
  MIN_LENGTH_ERROR: "You have not entered a min lenth of characters allowed",
  ROOM_NUMBER_REQUIRED_ERROR: "Please enter room number",
  ROOM_NUMBER_INVALID_ERROR: "Please enter a valid room number",
  TERMS_OF_SERVICE_REQUIRED_ERROR: "Please click the checkbox to agree to the terms of service",
  ACCESS_CODE_REQUIRED_ERROR: "Please enter access code",
};

const isValidName = (value: string) => {
  if (value) {
    return /^[a-zA-Z]([a-zA-Z '-]{1,100})?$/.test(value);
  }
  return true;
};

const isValidRoom = (value: string) => {
    return (!value || /^\d{3,4}$/.test(value))
}

export const loginValidators = {
  firstName: {
    required: helpers.withMessage(
      VALIDATION_MESSAGES.FIRST_NAME_REQUIRED_ERROR,
      required
    ),
    maxLength: helpers.withMessage(
      VALIDATION_MESSAGES.MAX_LENGTH_ERROR,
      maxLength(48)
    ),
    validName: helpers.withMessage(
      VALIDATION_MESSAGES.FIRST_NAME_INVALID_ERROR,
      isValidName
    ),
  },
  lastName: {
    required: helpers.withMessage(
      VALIDATION_MESSAGES.LAST_NAME_REQURIED_ERROR,
      required
    ),
    maxLength: helpers.withMessage(
      VALIDATION_MESSAGES.MAX_LENGTH_ERROR,
      maxLength(48)
    ),
    validName: helpers.withMessage(
      VALIDATION_MESSAGES.LAST_NAME_INVALID_ERROR,
      isValidName
    ),
  },
  email: {
    required: helpers.withMessage(
      VALIDATION_MESSAGES.EMAIL_REQUIRED_ERROR,
      required
    ),
    email: helpers.withMessage(VALIDATION_MESSAGES.EMAIL_INVALID_ERROR, email),
    maxLength: helpers.withMessage(
      VALIDATION_MESSAGES.MAX_LENGTH_ERROR,
      maxLength(64)
    ),
  },
  roomNumber: {
    required: helpers.withMessage(
      VALIDATION_MESSAGES.ROOM_NUMBER_REQUIRED_ERROR,
      required
    ),
    validRoom: helpers.withMessage(
      VALIDATION_MESSAGES.ROOM_NUMBER_INVALID_ERROR,
      isValidRoom
    )
  },
  status: {
    sameAs: helpers.withMessage(
      VALIDATION_MESSAGES.TERMS_OF_SERVICE_REQUIRED_ERROR,
      sameAs(true)
    )
  },
  accessCode: {
    required: helpers.withMessage(
      VALIDATION_MESSAGES.ACCESS_CODE_REQUIRED_ERROR,
      required
    )
  }
};
