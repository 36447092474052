import axios from "axios";
import { showPageSpinner, hidePageSpinner } from "./Spinner";
import configService from "@/services/ConfigService";

const CONFIG = configService.getConfig();

let skipSpinnerFlag = false;
const http = axios.create({
  baseURL: `${CONFIG?.VUE_APP_API_DOMAIN}`,
  headers: {
    "Content-type": "application/json"
  }
});

const httpPost = axios.create({
  baseURL: `${CONFIG?.VUE_APP_API_DOMAIN}`,
  headers: {
    "Content-type": "text/plain"
  },
  method: "POST"
});

const saveNewRelicInteraction = () => {
  if (window.newrelic && window.newrelic.interaction()) {
    window.newrelic.interaction().save();
  }
};

const setSkipSpinnerFlag = (skipSpinner : boolean) => {
  skipSpinnerFlag = skipSpinner;
};

const getSkipSpinnerFlag = () => {
  return skipSpinnerFlag;
};

const getCookie = (name: string) => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};
const getAuthCookieToken = (authCookie: string | null) => {
  if (authCookie) {
    const authCookieJSON = JSON.parse(authCookie);
    if (authCookieJSON && authCookieJSON.token) {
      return authCookieJSON.token;
    }
  }
  return null;
};
const getAuthCookieExpiry = (authCookie: string | null) => {
  if (authCookie) {
    const authCookieJSON = JSON.parse(authCookie);
    if (authCookieJSON && authCookieJSON.token) {
      const authCookieTokenJSON = JSON.parse(authCookieJSON.token);
      return authCookieTokenJSON.e;
    }
  }
  return null;
};
const getAuthCookie = () => {
  return getCookie("esa_guest_auth");
};

//   TODO: Temporarily commented out. Enable this soon.
// const addAuthHeaderToRequest = (config: AxiosRequestConfig) => {
//   const authCookie = getAuthCookie();
//   const authCookieToken = getAuthCookieToken(authCookie);
//   if (authCookieToken) {
//     config.headers["x-guest-auth"] = authCookieToken;
//   }
// };

const getBrowserIdentCookie = () => {
  return getCookie("esa_browseridentifier");
};

//   TODO: Temporarily commented out. Enable this soon.
// const addBrowserIdentHeaderToRequest = (config: AxiosRequestConfig) => {
//   const browserIdentCookie = getBrowserIdentCookie();
//   if (browserIdentCookie) {
//     config.headers["x-browseridentifier"] = browserIdentCookie;
//   }
// };

// before a request - start the spinner
http.interceptors.request.use(config => {
  if(!getSkipSpinnerFlag()){
    showPageSpinner();
  }
//   TODO: Temporarily commented out. Enable this soon.
  saveNewRelicInteraction();
//   addAuthHeaderToRequest(config);
//   addBrowserIdentHeaderToRequest(config);
  return config;
});

httpPost.interceptors.request.use(config => {
  if(!getSkipSpinnerFlag()){
    showPageSpinner();
  }
//   TODO: Temporarily commented out. Enable this soon.
  saveNewRelicInteraction();
//   addAuthHeaderToRequest(config);
//   addBrowserIdentHeaderToRequest(config);
  return config;
});

// after the response - stop the spinner
http.interceptors.response.use(
  response => {
    hidePageSpinner();
    return response;
  },
  err => {
    hidePageSpinner();
    throw err;
  }
);

httpPost.interceptors.response.use(
  response => {
    hidePageSpinner();
    return response;
  },
  err => {
    hidePageSpinner();
    throw err;
  }
);

export { getCookie, getAuthCookie, getAuthCookieExpiry, setSkipSpinnerFlag, http, httpPost };
