export default {
    local: {
        VUE_APP_API_DOMAIN: "http://localhost:8010/proxy",
        VUE_APP_BASE_DOMAIN: "/",
        VUE_APP_TITLE: "Extended Stay America",
        VUE_APP_IFRAME_URL: "https://development.extendedstayamerica.com/wifi-portal/"
    },
    dev: {
        VUE_APP_API_DOMAIN: "/",
        VUE_APP_BASE_DOMAIN: "/",
        VUE_APP_TITLE: "Extended Stay America",
        VUE_APP_IFRAME_URL: "https://development.extendedstayamerica.com/wifi-portal/"
    },
    test: {
        VUE_APP_API_DOMAIN: "/",
        VUE_APP_BASE_DOMAIN: "/",
        VUE_APP_TITLE: "Extended Stay America",
        VUE_APP_IFRAME_URL: "https://qa.extendedstayamerica.com/wifi-portal/"
    },
    uat: {
        VUE_APP_API_DOMAIN: "/",
        VUE_APP_BASE_DOMAIN: "/",
        VUE_APP_TITLE: "Extended Stay America",
        VUE_APP_IFRAME_URL: "https://prerele.extendedstayamerica.com/wifi-portal/"
    },
    prod: {
        VUE_APP_API_DOMAIN: "/",
        VUE_APP_BASE_DOMAIN: "/",
        VUE_APP_TITLE: "Extended Stay America",
        VUE_APP_IFRAME_URL: "https://www.extendedstayamerica.com/wifi-portal/"
    }
}