<template>
  <b-modal id="modal-scrollable" size="lg" scrollable content-class="esa-modal">
    <div class="margin-terms-of-service">
      <p>
        <strong
          ><u
            >ESA Wi-Fi Internet Service Agreement Terms &amp; Conditions</u
          ></strong
        >
      </p>
      <p><strong>WI-FI INTERNET SERVICE </strong><strong>AGREEMENT</strong></p>
      <p>
        Extended Stay America (ESA) provides its Internet services (as is
        provided by one or more third party internet service providers), as they
        may exist from time to time ("Services"), to this ESA property’s users.
        By using the Services, you agree to be bound by this Internet Service
        Agreement (“Agreement”) each time you use or access the Services at this
        ESA property during any visit (including future visits) and via any
        electronic device including but not limited to a laptop computer, mobile
        phone, or tablet. You also agree to use the Services in compliance with
        this Agreement, our Acceptable Use Policy, and ESA’s other applicable
        policies, as they govern your use of the Services. If you do not agree
        to the terms and conditions of this Agreement, including any future
        revisions, you may not use the Services and must terminate your use of
        the Services immediately. If you are not eighteen (18) years of age or
        older (or the legal age of majority, whichever is greater) or are
        otherwise incapable of entering into a legally binding agreement, you
        must immediately discontinue use of the Services.
      </p>

      <p>
        Your use of Services is governed by the following terms and conditions.
        Your use of Services constitutes an agreement between you and ESA and
        your acceptance of ESA’s Privacy and Security Statement (<a
          href="http://www.extendedstayamerica.com/privacy/default.html"
          target="_blank"
          >Click Here</a
        >
        for Privacy and Security Statement). Please review these terms and
        conditions carefully before using this Service.&nbsp;
      </p>
      <p>
        ESA may, from time to time, in its sole discretion, modify, add, or
        remove all or portions of these terms and conditions and, such changes
        will be effective following notice of the update by ESA. Therefore, you
        are expected to check these terms periodically for changes. Your
        continued use of the Services following any changes to these terms and
        conditions shall be deemed acceptance of any such changes.
      </p>
      <p>
        <strong>USER OBLIGATIONS</strong>
      </p>
      <p>
        As a condition of your use of the Services, you warrant to ESA that you
        will not use the Services for any purpose that is unlawful or prohibited
        by these terms, conditions, and notices. Additionally, while ESA is
        pleased to provide you with the Services during your stay, you agree not
        to download, share, or otherwise use any content or information to which
        you do not have the prior express right or permission to use, including
        the unauthorized use of the copyrighted content of another person or
        entity, such as movies, music, computer games or television shows.
      </p>
      <p>
        You also agree that you will always provide true, accurate, current, and
        complete information when submitting information to ESA to enable your
        use of the Services, including, without limitation, when you provide
        information via a submission form. In addition, you agree to abide by
        all applicable local, state, national, and international laws and
        regulations with respect to your use of the Services.
      </p>
      <p><strong>USER'S SECURITY</strong></p>
      <div>
        <a v-if="!isHidden" class="read-more" v-on:click="isHidden = !isHidden"
          >READ MORE</a
        >
        <div v-if="isHidden">
          <p>
            YOU AGREE THAT USE OF THE SERVICES IS AT YOUR OWN RISK. ESA shall
            not be liable whatsoever for any security issues which may arise
            while using the Services. You acknowledge that use of the Services
            is solely your responsibility and in your discretion. You are
            responsible for the safety and security of your device, data, and
            files. ESA encourages use of up-to-date virus protection, personal
            firewall, and other suitable security measures for the device you
            utilize to use the Services, though ESA does not endorse such virus
            protection services.
          </p>
          <p><strong>MONITORING THE SERVICES</strong></p>
          <p>
            ESA does not regularly monitor your use of the Services, or the
            content made available via the Services or otherwise made available
            over the Internet by third parties. However, ESA reserves the right
            to take action with respect to your unauthorized or impermissible
            use of the Services, including disclosure of information to law
            enforcement or government authorities regarding your use of the
            Services or suspension or termination of your use of the Services if
            ESA a reasonable basis to believe that your use has violated this
            Agreement or any laws, regulations, or governmental or legal
            requests or that suspension or termination is necessary to protect
            itself, the Services, or other users of the Services. Accordingly,
            ESA may immediately remove your material or information from ESA
            servers or otherwise block your use of the Services, in whole or in
            part, where ESA.
          </p>
          <p>
            <strong
              >DISCLAIMER OF WARRANTIES and LIMITATION OF LIABILITY</strong
            >
          </p>
          <p>
            YOU ACKNOWLEDGE THAT THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS
            AVAILABLE" BASIS AND MAY INCLUDE ERRORS, OMISSIONS, OR OTHER
            INACCURACIES. ESA EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT EXCEPT FOR CERTAIN PRODUCTS AND SERVICES
            SPECIFICALLY IDENTIFIED AS BEING OFFERED BY ESA, ESA DOES NOT
            CONTROL ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES ON THE
            INTERNET. THE INTERNET CONTAINS UNEDITED MATERIALS, SOME OF WHICH
            ARE SEXUALLY EXPLICIT OR MAY BE OFFENSIVE TO YOU. ESA HAS NO CONTROL
            OVER AND ACCEPTS NO RESPONSIBILITY FOR SUCH MATERIALS. YOU ASSUME
            FULL RESPONSIBILITY AND RISK FOR USE OF THE SERVICES AND THE
            INTERNET AND ARE SOLELY RESPONSIBLE FOR EVALUATING THE ACCURACY,
            COMPLETENESS, AND USEFULNESS OF ALL SERVICES, PRODUCTS, AND OTHER
            INFORMATION, AND THE QUALITY AND MERCHANTABILITY OF ALL MERCHANDISE
            PROVIDED THROUGH THE SERVICE OR THE INTERNET.
          </p>
          <p><strong>WAIVER AND SEVERABILITY</strong></p>
          <p>
            Failure to insist on strict performance of any of the terms and
            conditions of this Agreement will not operate as a waiver of any
            subsequent default or failure of performance. No waiver by ESA of
            any right under this Agreement will be deemed to be either a waiver
            of any other right or provision or a waiver of that same right or
            provision at any other time. If any part of this Agreement is
            determined to be invalid or unenforceable pursuant to applicable law
            including, but not limited to, the warranty disclaimers, venue,
            claim, and liability limitations set forth above, then the invalid
            or unenforceable provision will be deemed superseded by a valid,
            enforceable provision that most clearly matches the intent of the
            original provision and the remainder of this Agreement shall
            continue in effect.
          </p>
          <p><strong>MISCELLANEOUS</strong></p>
          <p>
            All rights to all ESA intellectual property (including without
            limitation copyrights, trademarks, trade names, patents, registered
            designs, aesthetics and/or functionality) are hereby reserved by
            ESA. This Agreement constitutes the entire agreement between you and
            ESA regarding its subject matter, and it supersedes any prior or
            contemporaneous agreements between you and ESA regarding its subject
            matter. You have no right to assign or delegate any right or
            responsibility. If any portion of this Agreement is found invalid,
            the remaining Agreement shall continue in effect. To the extent any
            dispute arises from this Agreement, such dispute shall be construed
            and administered in accordance with the laws of the state of North
            Carolina.
          </p>
          <p><strong>CONTACT</strong></p>
          <p>
            If you have questions regarding the Services or ESA, please contact
            ESA at
            <a href="mailto:guestrelations@extendedstay.com"
              >guestrelations@extendedstay.com.</a
            >
          </p>
          <p><strong>ACCEPTABLE USE POLICY</strong></p>
          <p><strong>INTRODUCTION</strong></p>
          <p>
            This Acceptable Use Policy ("AUP") governs your access to and use of
            the Services and is intended to enhance the use of the Services and
            Internet by preventing unacceptable use. All users of the Services
            must comply with this AUP. Your violation of this AUP may result in
            the suspension or termination of either your access to the Services
            and/or your ESA account or other actions. This AUP should be read in
            conjunction with our Internet Service Agreement and other policies.
            ESA reserves the right to modify the requirements of this AUP at any
            time by posting a revised AUP with a corresponding announcement of
            the revision online. Your continued use of the Services shall serve
            as an acceptance of the then-current version of this AUP.
          </p>
          <p>
            Any terms not defined in this AUP shall have the meanings given to
            them in the Internet Service Agreement.
          </p>
          <p><strong>VIOLATIONS OF AUP</strong></p>
          <p>
            You may use the Services only for lawful purposes and in accordance
            with this AUP. The following constitute violations of this AUP:
          </p>
          <ol>
            <li>
              Illegal use. Using the Services in any way that, intentionally or
              unintentionally, violates any applicable local, state, national or
              international law, or any rules or regulations promulgated
              thereunder.
            </li>
            <li>
              Harm to minors. Using the Services to exploit, harm, or attempt to
              harm, minors in any way.
            </li>
            <li>
              Threats. Using the Services to threaten or encourage bodily harm
              or destruction of property.
            </li>
            <li>Harassment. Using the Services to harass another.</li>
            <li>
              Fraudulent activity. Using the Services to make fraudulent offers
              to sell or buy products, items, or services or to advance any type
              of financial scam such as "pyramid schemes," "Ponzi schemes," and
              "chain letters."
            </li>
            <li>
              Forgery or impersonation. Using the Services to add, remove or
              modify identifying network header information in an effort to
              deceive or mislead, including attempting to impersonate any person
              by using forged headers or other identifying information.
            </li>
            <li>
              Unsolicited commercial email/Unsolicited bulk email. Using the
              Services to transmit any unsolicited “spam” commercial email or
              unsolicited bulk email. Activities that have the effect of
              facilitating unsolicited commercial email or unsolicited bulk
              email whether or not that email is commercial in nature are also
              prohibited.
            </li>
            <li>
              Unauthorized access. Using the Services to access, or to attempt
              to access, the accounts of others, or to penetrate, or attempt to
              penetrate, security measures of ESA or another entity's computer
              software or hardware, electronic communications system, or
              telecommunications system, whether or not the intrusion results in
              the corruption or loss of data.
            </li>
            <li>
              Copyright or trademark infringement. Using the Services to
              infringe any copyright, trademark, patent, trade secret, or other
              proprietary rights of any third party. Users also may not utilize
              this network to operate any music and/or video sharing programs,
              as these are illegal and detrimental to the functionality of the
              network as a whole. ESA reserves the right to stop and or
              terminate use by individuals displaying patterns of use
              characteristically similar to these kinds of activity without
              warning. Additionally, you may not use ESA’s name, trademarks, or
              service marks for any reason.
            </li>
            <li>
              Collection of personal data. Using the Services to collect, or
              attempt to collect, personal information about third parties
              without their knowledge or consent.
            </li>
            <li>
              Reselling the Services. Reselling the Services is expressly
              prohibited without the expressed written authorization from ESA.
            </li>
            <li>
              Network disruptions and unfriendly activity. Using the Services
              for any activity which adversely affects the ability of other
              people or systems to use ESA Services or the Internet.
              Additionally, you may not introduce any viruses, trojan horses, or
              other software or material which is malicious or technologically
              harmful.
            </li>
            <li>
              Hosting Services. ESA users are prohibited from providing hosting
              services of any kind while using the Services.
            </li>
          </ol>
          <p><strong>CONTENT STANDARDS</strong></p>
          <p>
            You agree to not use the Services to send, receive, upload,
            download, use, or re-use any material which:
          </p>
          <ol>
            <li>
              Contains any defamatory, obscene, offensive, harassing, violent,
              hateful, inflammatory, or otherwise objectionable material.
            </li>
            <li>
              Promotes sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
            </li>
            <li>
              Infringes any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person.
            </li>
            <li>
              Violates the legal rights of another or contains material that
              could give rise to any civil or criminal liability.
            </li>
            <li>Is likely to deceive any person</li>
            <li>
              Promotes any illegal activity, or advocates, promotes, or assists
              any unlawful act
            </li>
            <li>
              Causes annoyance, inconvenience, or needless anxiety or is likely
              to upset, embarrass, alarm, or annoy another.
            </li>
            <li>
              Involves commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising.
            </li>
            <li>
              Gives the impression that they emanate from or are endorsed by us
              or any other person or entity if this is not the case.
            </li>
          </ol>
          <p><strong>REPORTING VIOLATIONS OF AUP</strong></p>
          <p>
            ESA requests that anyone who believes that there is a violation of
            this AUP must direct the information to ESA at info@ESA.com or call
            (312) 850-3399.
          </p>
          <p>If available, please provide the following information:</p>
          <p>
            &ensp;&ensp; The IP address used to commit the alleged violation;
          </p>
          <p>
            &ensp;&ensp; The date and time of the alleged violation, including
            the time zone or offset from CST;
          </p>
          <p>&ensp;&ensp; Evidence of the alleged violation; and</p>
          <p>
            &ensp;&ensp; Email with full header information and providing all of
            the above.
          </p>
          <p>
            ESA may take, but is not limited to, any one or more of the
            following actions in response to complaints: (1) issue warnings
            (written or verbal), (2) suspend the user's account, (3) terminate
            the user's account, (4) bill the user for administrative costs
            and/or reactivation charges, (5) bring legal action to enjoin
            violations and/or to collect damages, if any, caused by violations.
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  name: "TermsOfServiceComponent",
  data() {
    return {
      isHidden: false,
    };
  },
});
</script>
