<template>
  <div id="app">
    <div class="esa-spinner-page" style="display:none">
      <img src="./assets/spinner-page.svg" alt="page-spinner" />
    </div>
    <router-view :payload="payload" :url="url" />
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from 'vue-cookies';

export default Vue.extend({
  data() {
    return {
      payload: {},url : {}
    }
  },
  mounted() {
    this.$root.$on("updateBuyUp", (payload, url) => {
      this.payload = payload;
      this.url = url;
      Vue.use(VueCookies);
      this.$cookies.set("payloadCookies", payload);
    })
  },
  unmounted(){
    this.$cookies.remove("payloadCookies");
  },
  methods: {
    appendScriptToHead(src) {
      const appScript = document.createElement("script");
      appScript.setAttribute("src", src);
      document.head.appendChild(appScript);
    },
    async mounted() {
      // used to bust the cache
      const ts = new Date().toString();

      this.appendScriptToHead(
        `/application/themes/esa/vendors~app.bundle.js?v=${ts}`
      );
      this.appendScriptToHead(
        `/application/themes/esa/app.bundle.js?v=${ts}`
      );
    }
  },
});
</script>
